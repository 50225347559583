import * as React from "react"
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

const EmptyState = () => {
  return (
    <div className="flex flex-col align-center items-center py-32 text-center">
      <PlusCircleIcon className="text-secondary w-8 h-8" />
      <h3 className="mt-2 text-lg font-medium text-primary">No Audits</h3>
      <p className="mt-1 text-lg text-secondary">
        Get started by auditing a new web page.
      </p>
      <div className="mt-6">
        <Link
          to="/new"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-bold rounded-md text-gray-900 bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Audit
        </Link>
      </div>
    </div>
  )
}

export default EmptyState
