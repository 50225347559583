import * as React from "react"
import format from "date-fns/format"
import { Link } from "gatsby"

import DashboardHeader from "../components/dashboard/DashboardHeader"
import StatusBadge from "../components/dashboard/StatusBadge"
import EmptyState from "../components/EmptyState/EmptyState"
import Layout from "../components/root/Layout"
import Table from "../components/Table/Table"

import {
  MapIcon,
  DocumentReportIcon,
  ExternalLinkIcon,
  GlobeAltIcon,
} from "@heroicons/react/outline"

import { LockClosedIcon, ThumbUpIcon } from "@heroicons/react/solid"

import CO2 from "../assets/Co2"
import { useSocket } from "../context/socket-context"

const TableOptionsColumn = ({ url, uid, publicSite }) => {
  return (
    <div className="flex space-x-4">
      <Link to={`/${publicSite ? "story" : "private-story"}/${uid}`}>
        <MapIcon className="h-6 w-6 btn-icon" />
      </Link>
      <Link to={`/report/${uid}`}>
        <DocumentReportIcon className="h-6 w-6 btn-icon" />
      </Link>
      <a href={`https://${url}`}>
        <ExternalLinkIcon className="h-6 w-6 btn-icon" />
      </a>
    </div>
  )
}

const TextWithIcon = ({ text, Icon }) => {
  return (
    <div className="flex space-x-2">
      <Icon className="h-6 w-6" />
      <p>{text}</p>
    </div>
  )
}

const Dashboard = () => {
  const { audits } = useSocket()
  const columns = [
    { accessor: "name", value: "Name" },
    { accessor: "lastAudit", value: "Last Audit" },
    { accessor: "score", value: "Status" },
    { accessor: "co2", value: "Info" },
    { accessor: "cdn", value: "CDN" },
    { accessor: "options", value: "Actions" },
  ]

  const fullTable = Object.keys(audits)
    .sort((a, b) => audits[b].timestamp - audits[a].timestamp)
    .map(
      auditID => {
        const audit = audits[auditID]
        let co2 = "-"
        let co2Number = 2.4
        if (audit.lighthouse) {
          const totalSizeMB =
            audit.lighthouse.audits.diagnostics.details.items[0]
              .totalByteWeight /
            1024 /
            1024
          co2 = (totalSizeMB * 10).toFixed(2) + "g"
          co2Number = totalSizeMB * 10
        }

        return {
          name: audit.name,
          lastAudit: format(new Date(audit.timestamp), "yyyy-MM-dd"),
          score: <StatusBadge score={audit?.lighthouse?.score} />,
          co2: <TextWithIcon text={co2} Icon={CO2} />,
          cdn: <TextWithIcon text={audit?.ipInfo?.isp} Icon={GlobeAltIcon} />,
          options: (
            <TableOptionsColumn
              url={audit.url}
              uid={auditID}
              publicSite={audit.public}
            />
          ),
          expandedContent: (
            <div className="px-6 py-3 grid grid-cols-2 w-full">
              <div className="flex flex-col">
                <div>
                  <div className="px-3 py-1 bg-secondary rounded-full text-sm uppercase inline-block">
                    <div className="flex items-center space-x-2">
                      {audit.public ? (
                        <ThumbUpIcon className="h-4 w-4 text-yellow-400" />
                      ) : (
                        <LockClosedIcon className="h-4 w-4 text-yellow-400" />
                      )}
                      <p>{audit.public ? "Public" : "Private"}</p>
                    </div>
                  </div>
                </div>
                {audit?.greenData?.hosted_by && (
                  <div className="flex flex-col">
                    <p>
                      Hosting Provider:{" "}
                      <span className="font-bold">
                        {audit?.greenData?.hosted_by}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="bg-gray-100 dark:bg-gray-800 rounded dash-table-row">
                <p>{co2}g of CO2 equates to:</p>
                <p>{(co2Number / 120.1).toFixed(2)}km drive</p>
              </div>
            </div>
          ),
        }
      },
      [audits]
    )

  React.useEffect(() => {
    setState({ ...state, filteredTable: fullTable })
  }, [audits])

  const [state, setState] = React.useState({
    filteredTable: fullTable,
    query: "",
  })

  const tableData = state.query ? state.filteredTable : fullTable

  const handleInputChange = event => {
    const query = event.target.value

    const filteredTable = fullTable.filter(item => {
      const name = item.name
      return name.toLowerCase().includes(query.toLowerCase())
    })

    setState({
      query,
      filteredTable,
    })
  }

  return (
    <Layout title="Dashboard">
      <div className="space-y-4">
        <DashboardHeader
          table={fullTable}
          handleInputChange={handleInputChange}
          query={state.query}
        />
        {fullTable.length === 0 ? (
          <EmptyState />
        ) : (
          <Table columns={columns} data={tableData} expandable />
        )}
      </div>
    </Layout>
  )
}

export default Dashboard
