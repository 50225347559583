import React from "react"
import { SearchIcon, PlusCircleIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import PrimaryButtonLink from "../buttons/PrimaryButtonLink"

const DashboardHeader = ({ table, query, handleInputChange }) => {
  return (
    <div className="flex w-full justify-between">
      <div className="relative w-1/2">
        <SearchIcon className="absolute top-0 left-0 ml-3.5 mt-2.5 h-6 w-6" />
        <input
          type="text"
          name="search-bar"
          id="search-bar"
          className="big-bad-input"
          placeholder="Search Audits"
          value={query}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex space-x-4 items-center">
        <div>
          <p className="whitespace-nowrap text-lg text-gray-400">
            <span className="font-medium">{table.length} of 50</span> sites
            tracked
          </p>
        </div>
        <PrimaryButtonLink
          to="/new"
          className={table.length >= 5 && "disabled"}
        >
          New <PlusCircleIcon className="ml-2 h-5 w-5 text-gray-900" />
        </PrimaryButtonLink>
      </div>
    </div>
  )
}

export default DashboardHeader
