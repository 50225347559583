import React from "react"

const CO2 = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className}`}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 463.01 463.01"
    >
      <path
        d="M236.234,300.093c6.524,0,11.451-2.283,15.063-6.979c3.716-4.83,5.6-11.241,5.6-19.055v-19.266
       c0-7.662-1.905-13.996-5.664-18.825c-3.66-4.704-8.59-6.99-15.071-6.99c-6.465,0-11.315,2.265-14.829,6.923
       c-3.629,4.812-5.469,11.168-5.469,18.893v19.266c0,7.819,1.863,14.234,5.537,19.066
       C224.961,297.813,229.814,300.093,236.234,300.093z"
      />
      <path
        d="M400.444,220.561c3.321-10.508,5.001-21.422,5.001-32.523c0-59.535-48.435-107.97-107.969-107.97
       c-36.11,0-70.11,18.412-90.006,48.394c-15.298-10.923-33.797-16.886-52.667-16.886c-49.978,0-90.638,40.66-90.638,90.639
       c0,5.925,0.575,11.824,1.712,17.602C27.97,227.549,0,261.358,0,300.538c0,45.438,36.966,82.404,82.403,82.404h298.205
       c45.437,0,82.402-36.967,82.402-82.404C463.01,262.331,437.021,229.555,400.444,220.561z M175.683,247.879l-0.835,2.513h-24.989
       v-2.5c0-6.322-1.474-11.162-4.382-14.387c-2.841-3.149-7.485-4.746-13.804-4.746c-5.562,0-9.855,2.197-13.125,6.717
       c-3.407,4.706-5.134,10.859-5.134,18.29v21.175c0,7.504,1.813,13.69,5.389,18.388c3.464,4.552,8.031,6.765,13.962,6.765
       c5.876,0,10.211-1.52,12.883-4.518c2.745-3.08,4.137-7.876,4.137-14.251v-2.5H174.7l0.837,2.511l0.006,0.384
       c0.205,12.095-3.578,21.863-11.244,29.035c-7.608,7.118-18.218,10.727-31.535,10.727c-13.415,0-24.482-4.396-32.896-13.068
       c-8.395-8.648-12.651-19.895-12.651-33.425v-21.05c0-13.465,4.149-24.702,12.333-33.397c8.226-8.739,19.033-13.17,32.122-13.17
       c13.69,0,24.598,3.613,32.42,10.74c7.9,7.199,11.801,17.086,11.596,29.386L175.683,247.879z M283.166,273.968
       c0,13.624-4.391,25.065-13.051,34.006c-8.681,8.963-20.079,13.507-33.881,13.507c-13.709,0-25.025-4.547-33.633-13.515
       c-8.582-8.939-12.934-20.378-12.934-33.998v-19.084c0-13.569,4.337-24.994,12.892-33.957c8.586-8.995,19.891-13.557,33.602-13.557
       c13.799,0,25.211,4.557,33.918,13.544c8.684,8.966,13.087,20.395,13.087,33.97V273.968z M376.656,319.951h-77.4v-17.397
       l34.979-37.503c4.75-5.45,8.195-10.133,10.185-13.857c1.883-3.525,2.837-6.793,2.837-9.712c0-3.893-0.986-6.95-3.014-9.348
       c-1.923-2.271-4.646-3.375-8.325-3.375c-4.119,0-7.127,1.312-9.197,4.011c-2.193,2.863-3.307,6.824-3.307,11.771v2.5h-24.985
       l-0.837-2.499l-0.01-0.375c-0.26-10.225,3.155-19.01,10.152-26.109c6.987-7.092,16.47-10.688,28.184-10.688
       c11.517,0,20.745,3.087,27.431,9.174c6.753,6.149,10.177,14.417,10.177,24.573c0,6.871-1.912,13.297-5.685,19.099
       c-3.583,5.511-9.539,12.79-17.704,21.638l-15.346,16.709h41.866V319.951z"
      />
    </svg>
  )
}

export default CO2
