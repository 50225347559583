import React from "react"

const StatusBadge = ({ score }) => {
  const color =
    score >= 0.75
      ? "bg-green-400"
      : score >= 0.5
      ? "bg-yellow-400"
      : "bg-red-400"
  const text = score >= 0.8 ? "Great" : score >= 0.5 ? "Warnings" : "Critical"
  return (
    <div className="inline-block">
      <div className="flex items-center space-x-2 h-6 bg-zinc-300 dark:bg-zinc-700 px-2 py-1 rounded">
        <div className={`h-3 w-3 rounded-full ${color}`} />
        <p className="text-sm text-gray-800 dark:text-gray-400">{text}</p>
      </div>
    </div>
  )
}

export default StatusBadge
